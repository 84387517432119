<template>
    <div class="flex-grow flex">
        <div class="flex-grow flex">
            <div class="w-full p-8 flex h-full bg-white border-r border-gray-200 justify-center">
                <validation-observer
                    v-slot="{ handleSubmit }"
                    class="w-full flex justify-center">
                    <form
                        class="md:w-1/2"
                        @submit.prevent="handleSubmit(signup)">
                        <router-link
                            :to="{ name: 'login' }"
                            class="no-underline flex items-center">
                            <i class="material-icons">keyboard_arrow_left</i>
                            Tilbage
                        </router-link>

                        <h1>Lav en bruger</h1>
                        <p>Det er gratis at oprette en bruger.</p>

                        <div class="form-group">
                            <v-input
                                id="username"
                                v-model="model.userName"
                                name="Brugernavn"
                                placeholder="example@missile.dk"
                                label="Brugernavn"
                                rule="required"/>
                        </div>

                        <div class="form-group">
                            <v-input
                                id="name"
                                v-model="model.name"
                                name="Dit navn"
                                placeholder="Mr Missile"
                                label="Dit navn"
                                rule="required"/>
                        </div>

                        <div class="form-group">
                            <v-input
                                id="password"
                                v-model="model.password"
                                type="password"
                                placeholder="********"
                                label="Adgangskode"
                                name="Adgangskode"
                                rule="required"/>
                        </div>

                        <div class="form-group">
                            <v-input
                                id="confirm-password"
                                v-model="model.confirmPassword"
                                type="password"
                                placeholder="********"
                                label="Bekræft adgangskode"
                                name="Adgangskode"
                                rule="required"/>
                        </div>

                        <div v-if="errors.length > 0">
                            <div
                                v-for="error in errors"
                                :key="error"
                                class="my-2 px-3 py-2 border border-red-700 rounded-sm bg-red-200 text-red-900 text-sm">
                                {{ error }}
                            </div>
                        </div>

                        <div class="form-group">
                            <button
                                type="submit"
                                class="btn --primary">
                                Opret
                            </button>
                        </div>
                    </form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticationService from '@/core/services/account/authentication.service';
import router from '@/router/router';
import teamsService from '@/core/services/teams/teams.service';
import taskTypesService from '@/core/services/tasktypes/taskTypes.service';
import bus from '@/core/Bus';
import { RegisterRequest } from '@/_typedef/missile';
import { ISignUpModel } from '../types/authentication.types';

    @Component({
        name: 'SignUp'
    })
export default class SignUp extends Vue {
    model: ISignUpModel = { userName: '', password: '', confirmPassword: '', name: '' };
    errors: string[] = [];

    async signup() {
        try {
            if (this.model.password !== this.model.confirmPassword) {
                this.errors.push('Adgangskoder skal være ens');
                return;
            }

            const request: RegisterRequest = {
                userName: this.model.userName,
                password: this.model.password,
                displayName: this.model.name
            };

            await authenticationService.register(request);

            await teamsService.getAll();
            await taskTypesService.getAll();

            router.push('/');

            bus.success(`Velkommen til Missile, ${this.model.name}`);
        } catch (errors: any) {
            this.errors = errors;
        }
    }
}
</script>